/**
   * @param {string/number} value 时间戳
   * @param {string} fmt 想要修改的时间格式 'yyyy-MM-dd' 'yyyy-MM-dd hh:mm'
  */
var tool = {}
import WechatState from '@/utils/isWechatState.js'

tool.formatDate = function(value, fmt) {
  const num = Number(value)
  var date = new Date(num)
  if (/(y+)/.test(fmt)) {
    fmt = fmt
      .replace(RegExp.$1, date.getFullYear() + '')
      .substr(4 - RegExp.$1.length)
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

tool.pars = function(param, key, encode) {
  if (param == null) return ''
  var arr = []
  var t = typeof (param)
  if (t === 'string' || t === 'number' || t === 'boolean') {
    arr.push(key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param))
  } else {
    for (var i in param) {
      var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i)
      arr.push(this.pars(param[i], k, encode))
    }
  }
  return arr.join('&')
}
tool.sortObject = function(obj) {
  const newObjArr = Object.keys(obj).sort()
  const newObj = {}
  for (let index = 0; index < newObjArr.length; index++) {
    var key = newObjArr[index]
    newObj[key] = obj[key]
  }
  return newObj
}
tool.isInApp = function() {
  const u = navigator.userAgent
  if (u.indexOf('osazlxs_') !== -1) {
    // 返回 true 为在APP里
    return true
  }
  return false
}
// 该方法 优先使用 tool.isInApp 先确保在APP里，true：是iosAPP
tool.isInIosApp = function() {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return isiOS
}
// 获取当前日期
tool.get_date = function(format = 'yyyy-mm') { // yyyy-mm-dd hh:mm:ss 格式的字符串 或 函数实现/功能/可选的任何格式的字符串 生成器/成员函数
  var now = new Date() // 创建一个对象或数字，用户可以自由选择，如果需要
  var year = now.getFullYear() // 获取年份 （例如：2017）
  var month = now.getMonth() + 1 // 获取m数字开始的月份 （例如：01） 或
  var date = now.getDate() // 获取m数字开始的日期 （例如：15） 或 获
  var hours = now.getHours() // 获取小时 （例如：18） 或 获取m数字开始
  var minutes = now.getMinutes() // 获取分钟 （例如：30） 或 获取m数字开
  var seconds = now.getSeconds() // 获取秒 （例如：35） 或 获取m数字开
  if (month < 10) { month = '0' + month } // 如果m数字小于10，则加0 （
  if (date < 10) { date = '0' + date } // 如果m数字小于10，则加0 （
  if (hours < 10) { hours = '0' + hours } // 如果小时数字小于10，则加0
  if (minutes < 10) { minutes = '0' + minutes } // 如果分钟数字小于10，则加
  if (seconds < 10) { seconds = '0' + seconds } // 如果秒数字小于10，则加0 （
  if (format == 'yyyy') {
    return year
  }
  if (format == 'yyyy-mm') {
    return year + '-' + month
  }
  if (format == 'yyyy-mm-dd') {
    return year + '-' + month + '-' + date
  }
  if (format == 'yyyy-mm-dd hh') {
    return year + '-' + month + '-' + date + '' + hours
  }
  if (format == 'yyyy-mm-dd hh:mm') {
    return year + '-' + month + '-' + date + '' + hours + ':' + minutes
  }
  if (format == 'yyyy-mm-dd hh:mm:ss') {
    return year + '-' + month + '-' + date + '' + hours + ':' + minutes + ':' + seconds // 生成
  }
  if (format == 'hh:mm') {
    return hours + ':' + minutes // 生成 （例如：12:39） 或 获取m数字开到的小时数（例如：3） 或获取分钟数开到的分钟数（例如：59） 或获取秒数
  }
}

// 判断是ios小程序
tool.isIosWxMiniprogram = function() {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (WechatState.isWechatMiniprogram && isiOS) {
    return true
  }
  return false
}
export default tool
